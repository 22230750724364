<template>
  <b-container class="flyer">
    <b-container>
        <b-row>
            <b-col>
                <b-button-group>
                    <b-button variant="secondary" :pressed="!isEnglish" @click="setDutch">NL</b-button>
                    <b-button variant="secondary" :pressed="isEnglish" @click="setEnglish">EN</b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-3">
                <h1>{{title}}</h1>
            </b-col>
        </b-row>

        <b-row v-if="mobile" class="mt-3">
            <b-col class="info">
                <h5 v-for="line in intro" :key="line">{{line}}</h5>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col>
                <p>
                    {{descr}}
                </p>
            </b-col>
            <b-col v-if="!mobile" class="info">
                <h5 v-for="line in intro" :key="line">{{line}}</h5>
            </b-col>
        </b-row>
    </b-container>
    <b-container>
        <b-row class="mt-5">
            <b-col>
                <b-img :src="sideimg1" class="sideimg1"></b-img>
                <b-img :src="sideimg2" class="sideimg2"></b-img>

                <h2 class="openinghours">{{schema}}</h2>
                <div class="mt-4">
                    <h5 v-for="openinghour in openinghours" :key="openinghour" class="openinghours">
                        {{ openinghour }}
                    </h5>
                </div>
            </b-col>
        </b-row>
    </b-container>
    <b-container class="mt-5">
        <b-row>
            <b-col>
                <h2>Geoff Harwood</h2>
                <b-img :src="geoffJPG" class="imageGeoff"></b-img>
                <p>{{ descrGeoffrey }}</p>
            </b-col>
            <b-col>
                <h2>Dirk Koene</h2>
                <b-img :src="dirkJPG" class="imageDirk"></b-img>
                <p>{{ descrDirk }}</p>
            </b-col>
        </b-row>
    </b-container>
  </b-container>
</template>

<script>
/*eslint-disable*/

import geoffJPG from "../assets/images/geoff.jpg";
import geoff2 from "../assets/images/geoff2.jpg";
import dirkJPG from "../assets/images/dirk.jpg";
import dirk1 from "../assets/images/dirk1.jpg";

const titleNL = () => "Kruisbestuiving"
const titleEN = () => "Crosspollination"

const schemaNL = () => "Openingstijden en aanwezigheid"
const schemaEN = () => "Opening hours and attendance"

const openinghoursNL = () => [
    "maandag: 14:00 - 19:00 | Dirk + Geoff",
    "dinsdag: 11:00 - 19:00 | Geoff",
    "woensdag: 11:00 - 19:00 | Geoff",
    "donderdag: 11:00 - 19:00 | Geoff",
    "vrijdag: 11:00 - 19:00 | Dirk + Geoff",
    "zaterdag: 11:00 - 19:00 | Dirk + Geoff | Receptie",
    "zondag: 11:00 - 19:00 | Dirk + Geoff"
]
const openinghoursEN = () => [
    "monday: 14:00 - 19:00 | Dirk + Geoff",
    "tuesday: 11:00 - 19:00 | Geoff",
    "wednesday: 11:00 - 19:00 | Geoff",
    "thursday: 11:00 - 19:00 | Geoff",
    "friday: 11:00 - 19:00 | Dirk + Geoff",
    "saturday: 11:00 - 19:00 | Dirk + Geoff | Reception",
    "sunday: 11:00 - 19:00 | Dirk + Geoff"
]

const introNL = () =>[
    "Wat? Tentoonstelling Modeltekeningen",
    "Wanneer? 23 t / m 29 januari 2023",
    "Wie? Geoff Harwood & Dirk Koene",
    "Waar? Galerie Waterbolk, Schoutenstraat 10, 3512 GB Utrecht"
]
const introEN = () => [
    "What? Exhibition of live drawings",
    "When? Januari 23rd - 29th, 2023",
    "Who? Geoff Harwood & Dirk Koene",
    "Where ? Waterbolk Gallery, Schoutenstraat 10, 3512 GB Utrecht"
]

const descrNL = () => "Deze tentoonstelling is een samenwerking tussen een veteraan uit de kunstwereld en \
een nieuwkomer, samengekomen hier in Utrecht. \
Beide kunstenaars hanteren een andere stijl, techniek en materiaal en deze tentoonstelling is het resultaat van deze kruisbestuiving. \
Graag presenteren wij het werk dat wij de afgelopen maanden vervaardigd hebben en nodigen je uit om deel te nemen aan onze kunstzinnige ontmoeting."
const descrEN = () => "This exhibition is a collaboration between a veteran of the art world and a newcomer, brought togethere here in Utrecht. \
Both artists utilise a different style, technique and material and this exhibition is the result of this crosspollination. \
With great pleasure we present our work from the past few months and invite you to join us in our artistic collision."

const storyDirkNL= () => "Ik ben Dirk en ik maak inmiddels mijn eerste stappen als kunstenaar. Mensen, lichamen en gezichtsuit- drukkingen \
in de kunst fascineren mij. Normaal gesproken werk ik met olieverf maar in houtskool zit net zoveel potentie. In mijn werk zie je de schilder in mij terug - Ik richt me op licht en \
donker en probeer textuur te suggereren binnen de beperkte tijd van de stand. "
const storyDirkEN = () => "I'm Dirk, and recently I have been making my first steps as an artist. People, bodies and facial expressions in art have always fascinated me. \
Usually I work with oil paints but charcoal has the same potency for me. In my work you can see the painter that's inside of me. I tend to focus on light and dark \
and try to use the limited amount of time to also suggest texture."

const storyGeoffNL = () => "Mijn naam is Geoff. Ik teken en schilder al vanaf mijn kindertijd. Mijn moeder was mijn eerste lerares \
en heeft me geïnspireerd om door te zetten. Het maken van kunst is voor mij een proces van transformatie, wat erg helend is. \
In deze recente tekeningen voel ik een losheid en gemak in vloeiende lijnen, waar met minder meer gezegd wordt. Ik hoop dat het gelukt is \
om de essentie van deze mooie en inspirerene modellen over te brengen. Er zijn veel dingen waar ik zonder kan maar schoonheid is daar niet een van.\
Ik hoop dat je net zo veel van werk geniet als dat ik deed van het maken ervan."
const storyGeoffEN = () => "My name’s Geoff. I’ve been drawing and painting since I was a child \
and my mother was my first teacher and inspired me to continue. \
For me making art is a process of transformation which is  \
healing. \
In these recent drawings I feel a looseness and ease in flowing lines \
where less is more. I hope I have conveyed the essence of these \
beautiful and inspiring models. There are many things I can live \
without but beauty is not one of them.I hope you enjoy them as \
    much as I did making them."

export default {
    data() {
        return {
            sideimg1: geoff2,
            sideimg2: dirk1,
            geoffJPG: geoffJPG,
            dirkJPG: dirkJPG,
            isEnglish: false,
            title: "",
            intro: "",
            descr: "",
            descrGeoffrey: "",
            descrDirk: "",
            schema: "",
            openinghours: [],
        }
    },
    created() {
        this.setDutch();
    },
    methods: {
        setEnglish() {
            this.isEnglish = true;
            this.descr = descrEN();
            this.title = titleEN();
            this.intro = introEN();
            this.descrDirk = storyDirkEN();
            this.descrGeoffrey = storyGeoffEN();
            this.schema = schemaEN();
            this.openinghours = openinghoursEN();
        },
        setDutch() {
            this.isEnglish = false;
            this.title = titleNL();
            this.intro = introNL();
            this.descr = descrNL();
            this.descrDirk = storyDirkNL();
            this.descrGeoffrey = storyGeoffNL();
            this.schema = schemaNL();
            this.openinghours = openinghoursNL();
        },
    },
    computed: {
        mobile() {
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
        }
    }
}
</script>

<style>
.flyer h5 {
    line-height: 1.6;
    letter-spacing: 3px;
    font-weight:700 !important;
}
.openinghours {
    text-align: center;
}
.flyer p {
    line-height: 2;
    letter-spacing: 2px;
}
.btn:focus {
    box-shadow: none !important;
}
h1 {
    font-size:100px !important;
    letter-spacing: 12px;
    color: black;
}

.imageGeoff {
    float: left;
    margin-right: 20px;
    margin-top: 10px;
    border-radius: 50%;
    shape-outside: ellipse();
}
.imageDirk {
    float: left;
    margin-right: 20px;
    margin-top: 10px;
    border-radius: 50%;
    shape-outside: ellipse();
}
.sideimg1 {
    float: left;
}
.sideimg2 {
    float: right;
}
@media only screen and (max-width: 500px) {
    h1 {
        font-size: 30px !important;
        letter-spacing: 4px;
        font-weight: 700;
        color: black;
    }
    .flyer h5 {
        letter-spacing: 1px;
        font-size: 12px;
    }
    p {
        text-align: left;
        font-size: 10px;
        line-height: normal;
    }
    .openinghours {
        text-align: left;
    }
    .imageGeoff {
        float: left;
        margin-right: 20px;
        margin-top: 10px;
        border-radius: 0%;
        shape-outside: none;
        width: 45%;
    }

    .imageDirk {
        float: left;
        margin-right: 20px;
        margin-top: 10px;
        border-radius: 0%;
        shape-outside: none;
        width: 45%;
    }
    .sideimg1 {
        float: left;
        margin-bottom: 70px;
        width: 45%;
        height: 260px;
    }

    .sideimg2 {
        float: right;
        height: 270px;
        margin-bottom: 70px;
        width: 45%;
        height: 260px;
    }
}
</style>